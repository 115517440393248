var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        maskClosable: false,
        dialogClass: "translate-modal",
        visible: _vm.visible,
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal-title",
          attrs: { slot: "title" },
          on: { mouseenter: _vm.mousemove, mouseleave: _vm.mouseout },
          slot: "title",
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.selectErrorType > 3 ? "驳回" : "错误类型") +
              "\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "symbol-list",
          on: { mouseenter: _vm.mousemove, mouseleave: _vm.mouseout },
        },
        [
          _vm.selectErrorType > 3
            ? _c(
                "div",
                [
                  _c("j-editor", {
                    staticClass: "error-editor",
                    attrs: { maxWidth: "200px", toolbar: [] },
                    model: {
                      value: _vm.errorValue,
                      callback: function ($$v) {
                        _vm.errorValue = $$v
                      },
                      expression: "errorValue",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.errorValue,
                        callback: function ($$v) {
                          _vm.errorValue = $$v
                        },
                        expression: "errorValue",
                      },
                    },
                    _vm._l(_vm.dictList, function (errorItem) {
                      return _c(
                        "a-radio",
                        {
                          key: errorItem.id,
                          style: _vm.radioStyle,
                          attrs: { value: errorItem.itemText },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(errorItem.itemText) +
                              "\n        "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
        ]
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { on: { mouseenter: _vm.mousemove, mouseleave: _vm.mouseout } },
          [
            _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
              _vm._v("\n        取消\n      "),
            ]),
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { type: "primary" },
                on: { click: _vm.handleOk },
              },
              [_vm._v("\n        完成\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }